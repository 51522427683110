@mixin font($font_name, $file_name, $weight, $style) {

    @font-face {
        font-family: $font_name;
        font-display: swap;
        src: url("../fonts/#{$file_name}.woff") format("woff"), url("../fonts/#{$file_name}.woff2") format("woff2");
        font-weight: #{$weight};
        font-style: #{$style};
    }
}

@import 'fonts';
@import 'config';
@import 'header';

// commons
@import 'select';
@import "catalog-item";

// INDEX

@import "main-hero";
@import "about";
@import "main-catalog";
@import "map";

// CATALOG

@import "catalog";

// DETAIL

@import "detail";
@import "more-objects";

// OWNERS

@import "owners";

// POLICY

@import "policy";

// 404

@import 'footer';
@import 'modal';
