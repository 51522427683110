@font-face {
  font-family: "Inter";
  font-display: swap;
  src: url("../fonts/Inter-Light.woff") format("woff"),url("../fonts/Inter-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  font-display: swap;
  src: url("../fonts/Inter-Regular.woff") format("woff"),url("../fonts/Inter-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  font-display: swap;
  src: url("../fonts/Inter-Medium.woff") format("woff"),url("../fonts/Inter-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  font-display: swap;
  src: url("../fonts/Inter-SemiBold.woff") format("woff"),url("../fonts/Inter-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  font-display: swap;
  src: url("../fonts/Inter-Bold.woff") format("woff"),url("../fonts/Inter-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  font-display: swap;
  src: url("../fonts/Montserrat-Light.woff") format("woff"),url("../fonts/Montserrat-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  font-display: swap;
  src: url("../fonts/Montserrat-Regular.woff") format("woff"),url("../fonts/Montserrat-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  font-display: swap;
  src: url("../fonts/Montserrat-SemiBold.woff") format("woff"),url("../fonts/Montserrat-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  font-display: swap;
  src: url("../fonts/Montserrat-Bold.woff") format("woff"),url("../fonts/Montserrat-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

:root {
  --font-size: 18px;
  --inter: 'Inter', sans-serif;
  --montserrat: 'Montserrat', sans-serif;
  --main-color: #fff;
  --main-color-opacity: #8c8c8c;
  --container: 1320px;
  --bg-color: #101011;
}

html {
  font-size: var(--font-size);
  font-weight: 400;
}

html,
body {
  scrollbar-gutter: stable;
}

body {
  position: relative;
  margin: 0;
  font-family: var(--inter);
  line-height: normal;
  color: var(--main-color);
  background-color: var(--bg-color);
  overflow-x: hidden;
}

body * {
  font-family: var(--inter);
  line-height: normal;
}

body.no-scroll {
  overflow: hidden;
}

main {
  position: relative;
  background-color: var(--bg-color);
  overflow: hidden;
}

main.z-index {
  position: relative;
  z-index: 4;
}

section {
  position: relative;
  z-index: 1;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

ul,
ol,
li {
  display: block;
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}

*::before,
*::after {
  box-sizing: border-box;
}

a,
a:hover,
a:active,
a:focus,
button,
input,
button:hover,
button:active,
button:focus,
input:focus,
input:active {
  text-decoration: none;
  outline: none;
}

a {
  color: inherit;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'],
input[type="number"]:hover,
input[type="number"]:focus {
  appearance: none;
  -moz-appearance: textfield;
}

img,
picture {
  display: block;
}

img {
  width: 100%;
  height: max-content;
  object-fit: contain;
}

pre {
  margin: 0 !important;
  white-space: break-spaces;
}

.container {
  position: relative;
  max-width: var(--container);
  width: 100%;
  margin: 0 auto;
}

.pointer {
  cursor: pointer;
}

.upper {
  text-transform: uppercase;
}

nav li a {
  transition: color .5s ease-in-out;
}

nav li:hover {
  color: var(--orange);
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 46px;
  padding: 0;
  color: var(--bg-color);
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
  border-radius: 6px;
  cursor: pointer;
  -webkit-appearance: none;
  transition: background-color .3s ease-in-out, border-color .3s ease-in-out, color .3s ease-in-out;
}

.btn:hover,
.btn:focus-visible {
  background-color: #8c8c8c;
  border-color: #8c8c8c;
  color: #fff;
}

.btn.btn_black {
  color: #fff;
  background-color: transparent;
}

.btn.btn_black:hover,
.btn.btn_black:focus-visible {
  color: #8c8c8c;
  border-color: #8c8c8c;
}

.tablet {
  display: none;
}

.mobile {
  display: none;
}

.slider-dots {
  display: flex;
  align-items: center;
  gap: 8px;
  width: max-content;
  margin: 0 auto;
}

.slider-dots li {
  width: 6px;
  height: 6px;
  background-color: transparent;
  border: 1px solid #8c8c8c;
  border-radius: 50%;
  cursor: pointer;
  transition: all .3s ease-in-out;
}

.slider-dots li.slick-active {
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-color: #fff;
}

.slider-dots li button {
  font-size: 0;
  background-color: transparent;
  border: 0;
}

.loader::before {
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background: rgba(255,255,255,0.6) url("../img/icons/spinner.svg") center/100px no-repeat;
}

.disabled {
  pointer-events: none;
  opacity: .8;
}

.form__row-wrap_with-uint {
  position: relative;
  overflow: hidden;
}

.form__row-wrap_with-uint.show .value-text {
  display: block;
}

.form__row-wrap_with-uint input {
  width: 100% !important;
  height: 100%;
}

.form__row-wrap_with-uint input:focus-visible::placeholder {
  font-size: 0;
}

.form__row-wrap_with-uint .value-text {
  position: absolute;
  top: 50%;
  left: 16px;
  display: none;
  margin: auto;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  transform: translateY(-50%);
}

.form__row-wrap_with-uint .value-unit {
  left: 65px;
}

.form__row-wrap_with-uint .input_span_spoof {
  position: absolute;
  white-space: nowrap;
  font-size: inherit;
  opacity: 0;
  visibility: hidden;
}

.validate-border {
  border-color: red !important;
}

.not-checked .custom-checkbox {
  border-color: red !important;
}

.main-page,
.owners-page {
  background-color: transparent;
}

.main-page .header,
.owners-page .header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  width: 100%;
  height: max-content;
  background-color: transparent;
  transition: background-color .3s ease-in-out;
}

.nav_scroll.main-page .header,
.nav_scroll.owners-page .header {
  background-color: #191919;
}

.nav_scroll .header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.header {
  position: relative;
  z-index: 3;
  background-color: #191919;
}

.header .container {
  display: flex;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid var(--main-color);
}

.header .container>.header__phone {
  display: none;
}

.header__logo .logo-mobile {
  display: none;
}

.header__wrapper {
  margin-left: auto;
  transition: transform .3s ease-in-out, opacity .3s ease-in-out;
}

.header__wrapper .hamburger-wrap {
  display: flex;
  align-items: center;
  gap: 24px;
}

.hamburger-wrap__close {
  display: none;
}

.header__nav ul {
  display: flex;
  align-items: center;
  gap: 24px;
}

.header__nav li:hover a,
.header__nav li:focus-visible a {
  text-decoration: underline;
}

.header__nav a {
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
}

.header__phone {
  margin-right: 7px;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
}

.header__phone:hover,
.header__phone:focus-visible {
  text-decoration: underline;
}

.header__socials {
  display: flex;
  gap: 12px;
}

.header__socials li a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: rgba(16,16,17,0.5);
  border-radius: 6px;
  transition: background-color .3s ease-in-out;
}

.header__socials li .svg-ico {
  color: var(--main-color);
  transition: color .3s ease-in-out;
}

.header__socials li:hover a,
.header__socials li:focus-visible a {
  background-color: var(--main-color);
}

.header__socials li:hover .svg-ico,
.header__socials li:focus-visible .svg-ico {
  color: #DADADA;
}

.header__hamburger {
  display: none;
}

.select {
  position: relative;
}

.select.open .select-title {
  border-radius: 6px 6px 0 0;
}

.select.open .select-title::after {
  position: absolute;
  right: 18px;
  z-index: 3;
  transform: rotate(180deg);
}

.select.open .select-content {
  opacity: 1;
  transform: scaleY(1);
  transition: transform .3s ease-in-out;
}

.select.active .select-title {
  color: #fff;
}

.select-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  color: #8c8c8c;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.1px;
  font-weight: 400;
  background-color: transparent;
  border: 1px solid var(--main-color);
  border-radius: 6px;
  cursor: pointer;
  transition: border-color .3s ease-in-out;
}

.select-title:hover,
.select-title:focus-visible {
  border-color: #8c8c8c;
}

.select-title::after {
  content: '';
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background: url("../img/icons/select-arrow.svg") center/contain no-repeat;
  transition: transform .3s ease-in-out;
}

.select-content {
  position: absolute;
  top: 1px;
  right: 0;
  left: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px 24px 11px;
  background-color: var(--bg-color);
  border: 1px solid var(--main-color);
  border-top: 0;
  border-radius: 6px;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: 100% 0;
  transition: opacity .3s ease-in-out;
}

.select-content::-webkit-scrollbar-thumb {
  background: var(--main-color);
}

.select-content::-webkit-scrollbar {
  width: 6px;
}

.select-content::-webkit-scrollbar-track {
  background: var(--bg-color);
}

.select-content__wrapper {
  display: flex;
  cursor: pointer;
}

.select-content__wrapper:hover .select-content__radio {
  color: var(--main-color);
}

.select-content__wrapper input {
  display: none !important;
}

.select-content__wrapper input:checked ~ .select-content__radio {
  color: var(--main-color);
}

.select-content__radio {
  color: #8c8c8c;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.1px;
  transition: color .3s ease-in-out;
}

.catalog-item-wrap {
  width: 100%;
  height: calc((var(--container) / 3 - 16px) / 1.13);
}

.catalog-item-wrap .catalog-item {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.catalog-item-wrap .catalog-item.swipe .catalog-item__link {
  display: none;
}

.catalog-item-wrap .catalog-item__images-wrap {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: calc(var(--container) / 3 - 16px);
  height: 100%;
  background: linear-gradient(180deg, rgba(16,16,17,0.64) 0%, rgba(16,16,17,0) 16.15%, rgba(16,16,17,0) 58.33%, #101011 100%);
}

.catalog-item-wrap .catalog-item__images.slick-slider {
  margin-bottom: 0;
}

.catalog-item-wrap .catalog-item__images .catalog-item__image {
  position: relative;
}

.catalog-item-wrap .catalog-item__images .catalog-item__image::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 101%;
  height: 101%;
  background: linear-gradient(180deg, rgba(16,16,17,0.64) 0%, rgba(16,16,17,0) 16.15%, rgba(16,16,17,0) 58.33%, #101011 100%);
}

.catalog-item-wrap .catalog-item__images .catalog-item__image:not(:first-child) {
  display: none;
}

.catalog-item-wrap .catalog-item__image {
  width: 100%;
  height: calc((var(--container) / 3 - 16px) / 1.13);
}

.catalog-item-wrap .catalog-item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.catalog-item-wrap .catalog-item__images-navs {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  display: flex;
  align-items: flex-end;
  height: 50px;
  padding-bottom: 20px;
  margin: auto;
}

.catalog-item-wrap .catalog-item__images-navs .slider-dots {
  z-index: 100;
  height: 30px;
  margin-top: auto;
}

.catalog-item-wrap .catalog-item__images-navs .slider-dots li {
  z-index: 101;
}

.catalog-item-wrap .catalog-item__stickers {
  position: absolute;
  top: 24px;
  left: 24px;
  z-index: 3;
  display: flex;
  align-items: center;
  gap: 16px;
}

.catalog-item-wrap .catalog-item__stickers .sticker {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  background-color: rgba(16,16,17,0.5);
  border-radius: 6px;
  cursor: pointer;
}

.catalog-item-wrap .catalog-item__stickers .sticker:hover>.sticker-help-text,
.catalog-item-wrap .catalog-item__stickers .sticker:focus-visible>.sticker-help-text,
.catalog-item-wrap .catalog-item__stickers .sticker.show>.sticker-help-text {
  opacity: 1;
}

.catalog-item-wrap .catalog-item__stickers .sticker-help-text {
  position: absolute;
  bottom: -9px;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  height: 44px;
  padding: 0 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.1px;
  color: #fff;
  opacity: 0;
  border-radius: 6px;
  background: rgba(16,16,17,0.5);
  box-shadow: 0 1px 2px 0 rgba(16,24,40,0.04);
  transform: translateY(100%);
  user-select: none;
  transition: opacity .3s ease-in-out;
}

.catalog-item-wrap .catalog-item__stickers .svg-ico {
  color: #fff;
}

.catalog-item-wrap .catalog-item__content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  height: max-content;
  padding: 0 24px 56px;
}

.catalog-item-wrap .catalog-item__price {
  font-family: var(--montserrat);
  font-variant-numeric: lining-nums tabular-nums;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.01em;
}

.catalog-item-wrap .catalog-item__quadrature {
  font-size: 16px;
  font-weight: 500;
}

.catalog-item-wrap .catalog-item__place {
  font-size: 16px;
}

.catalog-item-wrap .catalog-item__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.main-hero {
  z-index: 2;
  background: transparent url("../img/main-bg.png") center/cover no-repeat;
}

.main-hero::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(16,16,17,0.5);
}

.main-hero .container {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 176px;
  padding: 256px 0 88px;
}

.main-hero__title {
  max-width: 1095px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  font-weight: 300;
  font-size: 64px;
  line-height: 90px;
  text-transform: uppercase;
}

.main-hero__form {
  width: 100%;
  padding: 25px 25.5px;
  background-color: var(--bg-color);
  border-radius: 6px;
}

.main-hero__form .form__row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.main-hero__form .form__row>*:not(.form-submit) {
  width: calc((var(--container) - 126px - 51px) / 3 - 111px);
}

.main-hero__form .select,
.main-hero__form .common-input {
  width: calc(25% - 54px);
}

.main-hero__form .form__row-wrap_with-uint.show input {
  padding-left: 35px;
}

.main-hero__form .form__row-wrap_with-uint .value-text,
.main-hero__form .form__row-wrap_with-uint .input_span_spoof {
  font-size: 15px;
  line-height: 22px;
}

.main-hero__form .form__row-wrap_with-uint .value-unit {
  left: 40px;
}

.main-hero__form .common-input {
  padding: 12px 16px;
  color: #fff;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.1px;
  background-color: transparent;
  border: 1px solid var(--main-color);
  border-radius: 6px;
  transition: border-color .3s ease-in-out;
}

.main-hero__form .common-input:hover,
.main-hero__form .common-input:focus-visible {
  border-color: #8c8c8c;
}

.main-hero__form .common-input::placeholder {
  color: #8c8c8c;
  opacity: 1;
}

.main-hero__form .common-input[name="min_quadrature"] {
  padding-right: 21px;
}

.main-hero__form .form-submit {
  width: 126px;
}

.about {
  padding: 50.5px 0;
}

.about .container {
  display: flex;
  gap: 138px;
  max-width: 100vw;
  padding-left: calc((100vw - var(--container)) / 2);
  padding-right: calc((100vw - var(--container)) / 2 - 60px);
}

.about__left {
  display: flex;
  flex-direction: column;
  gap: 17px;
  max-width: 40.45%;
  width: 100%;
  padding: 64px 0 25px;
}

.about__description {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.about__description p {
  font-weight: 300;
  line-height: 26px;
  letter-spacing: -0.1px;
}

.about__btn {
  width: 200px;
}

.about__right {
  margin-right: calc((100vw - var(--container)) / -2);
  width: calc(100vw - var(--container) * .4045 - 138px);
}

.about__right img {
  width: 100%;
  height: 430px;
  object-fit: cover;
}

.about__right img.tablet img {
  height: auto;
}

.main-catalog {
  padding: 44px 0 60px;
  background-color: #191919;
}

.main-catalog__tabs-wrapper {
  display: flex;
  align-items: center;
  gap: 60px;
  margin-bottom: 44px;
}

.main-catalog__tabs-wrapper.tabs-small {
  display: none;
}

.main-catalog__tabs {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 24px;
}

.main-catalog__tabs li {
  position: relative;
  width: max-content;
  color: #8c8c8c;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  cursor: pointer;
  transition: color .3s ease-in-out;
}

.main-catalog__tabs li:hover,
.main-catalog__tabs li:focus-visible {
  color: #fff;
}

.main-catalog__tabs li::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: transparent;
  transition: background-color .3s ease-in-out;
}

.main-catalog__tabs li.active {
  color: #fff;
}

.main-catalog__tabs li.active::after {
  background-color: #fff;
}

.main-catalog__tabs-row {
  display: flex;
  gap: 16px;
  width: 100%;
}

.main-catalog__tabs-row .tabs-radio {
  min-width: calc(50% - 8px);
}

.main-catalog__tabs-row .tabs-radio input {
  display: none;
}

.main-catalog__tabs-row .tabs-radio input:checked ~ .tabs-radio__custom {
  color: var(--bg-color);
  background-color: #fff;
}

.main-catalog__tabs-row .tabs-radio input:checked ~ .tabs-radio__custom:hover,
.main-catalog__tabs-row .tabs-radio input:checked ~ .tabs-radio__custom:focus-visible {
  color: #fff;
  background-color: #8c8c8c;
}

.main-catalog__tabs-row .tabs-radio__custom {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 46px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 6px;
  cursor: pointer;
  transition: border-color .3s ease-in-out, color .3s ease-in-out, background-color .3s ease-in-out;
}

.main-catalog__tabs-row .tabs-radio__custom:hover,
.main-catalog__tabs-row .tabs-radio__custom:focus-visible {
  color: #8c8c8c;
  border-color: #8c8c8c;
}

.main-catalog__tabs-row .select {
  width: 100%;
}

.main-catalog__tabs-row .tabs-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 46px;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  border: 1px solid #fff;
  border-radius: 6px;
  transition: border-color .3s ease-in-out, color .3s ease-in-out;
}

.main-catalog__tabs-row .tabs-link:hover,
.main-catalog__tabs-row .tabs-link:focus-visible {
  color: #8c8c8c;
  border-color: #8c8c8c;
}

.main-catalog__wrap {
  margin-bottom: 32px;
}

.main-catalog__list {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.main-catalog__list:not(.list-last) {
  display: none;
}

.main-catalog__list.slick-slider {
  margin-bottom: 0 !important;
}

.main-catalog__list .slick-list {
  height: 100%;
}

.main-catalog__list .slick-track {
  height: 100%;
}

.main-catalog__list .slick-slide>div {
  height: 100%;
}

.main-catalog__list .catalog-item-wrap {
  max-width: calc(33.33% - 16px);
}

.main-catalog__list .catalog-item-wrap .catalog-item__images {
  height: 100%;
}

.main-catalog__list_empty {
  display: flex;
  width: 100%;
  margin: 48px 0 32px;
  font-weight: 600;
  font-size: 32px;
  line-height: 1.2;
}

.main-catalog__navs {
  display: none;
}

.main-catalog__link {
  width: 140px;
  margin: 0 auto;
  font-size: 16px;
}

.map-section {
  position: relative;
  width: 100vw;
  height: max-content;
  padding-top: 61px;
}

.map-section__title {
  max-width: 800px;
  margin: 0 auto 56px;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: -0.1px;
}

.map-section__stickers {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 30px;
  background-color: #101011;
  border-radius: 6px;
}

.map-section__stickers .stickers__close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.map-section__stickers .stickers__close::after {
  content: '';
  width: 10px;
  height: 11px;
  background: transparent url("../img/icons/close-bars.svg") center/auto no-repeat;
}

.map-section__stickers .sticker {
  display: flex;
  align-items: center;
  gap: 5px;
}

.map-section__stickers .sticker__color-line {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  width: 45px;
  height: 15px;
}

.map-section__stickers .sticker__text {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: -.1px;
}

#map {
  position: relative;
  width: 100%;
  height: 500px;
}

#map .bars-content__item-wrapper {
  width: max-content;
  height: max-content;
  transform: translate(-50%, -100%);
  border-radius: 6px;
}

#map .bars-content__item-wrapper.parking .bars-content__item-wrap {
  border-radius: 6px;
}

#map .bars-content__item-wrapper.parking .bars-content__item-image,
#map .bars-content__item-wrapper.parking .bars-content__parking-inf {
  display: none;
}

#map .bars-content__item {
  position: relative;
  width: max-content;
  background: #F7F9FA top center / 100% 106px no-repeat;
  box-shadow: 0 4px 12px 2px rgba(0,0,0,0.5);
  border-radius: 5px;
}

#map .bars-content__item>* {
  width: max-content !important;
  height: max-content !important;
}

#map .bars-content__item>*>* {
  display: flex;
}

#map .bars-content__item-close,
#map .parking-inf-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

#map .bars-content__item-close::after,
#map .parking-inf-close::after {
  content: '';
  width: 10px;
  height: 11px;
  background: transparent url("../img/icons/close-bars.svg") center/auto no-repeat;
}

#map .bars-content__item-image {
  flex-shrink: 0;
  width: 323.5px;
  min-height: 224px;
  height: 100%;
  border-radius: 6px 0 0 6px;
}

#map .bars-content__item-image img {
  min-height: 224px;
  height: 100%;
  object-fit: cover;
  border-radius: 6px 0 0 6px;
}

#map .bars-content__item-wrap {
  width: 323.5px;
  padding: 24px 33.25px;
  background-color: #101011;
  border-radius: 0 6px 6px 0;
}

#map .bars-content__item-wrap .item__subtitle {
  color: rgba(255,255,255,0.5);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
}

#map .bars-content__item-wrap .item__address {
  margin-top: 16px;
}

#map .bars-content__item-wrap .item__address,
#map .bars-content__item-wrap .item__floors {
  margin-bottom: 24px;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: -0.1px;
}

#map .bars-content__item-wrap .item__floors {
  margin-bottom: 0;
}

#map .bars-content__item-title {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.22px;
  color: #fff;
}

#map .bars-content__parking-inf {
  position: absolute;
  right: 0;
  bottom: -42.5px;
  width: 300px;
  padding: 19.5px 22px 30.5px;
  border-radius: 6px;
  background-color: rgba(16,16,17,0.8);
  transform: translateY(100%);
}

#map .bars-content__parking-inf.not-show {
  display: none !important;
}

#map .bars-content__parking-inf .parking-inf__title {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.22px;
}

#map .bars-content__parking-inf .parking-inf__description {
  color: rgba(255,255,255,0.5);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
}

[class*="ymaps-2"] [class*="-ground-pane"] {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></symbol>#grayscale");
  -webkit-filter: grayscale(100%);
}

.catalog {
  padding: 50px 0 40px;
  background-color: #191919;
}

.catalog .container {
  display: flex;
  gap: 24px;
}

.catalog__filters {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: calc(33.33% - 12px);
  width: 100%;
  height: max-content;
}

.catalog__filters .filters-row {
  display: flex;
  gap: 16px;
  width: 100%;
}

.catalog__filters .filters-row_inputs {
  position: relative;
  gap: 0;
  width: 100%;
  height: 46px;
  color: #fff;
  font-size: 16px;
  line-height: 22px;
  border: 1px solid #fff;
  border-radius: 6px;
  transition: border-color .3s ease-in-out, color .3s ease-in-out;
}

.catalog__filters .filters-row_inputs:hover,
.catalog__filters .filters-row_inputs:focus-visible {
  color: #8c8c8c;
  border-color: #8c8c8c;
}

.catalog__filters .filters-row_inputs::after {
  content: '|';
  position: absolute;
  top: 0;
  left: calc(52.26% - 16px);
  bottom: 0;
  height: max-content;
  margin: auto;
  color: #8c8c8c;
}

.catalog__filters .filters-row_inputs input {
  color: #fff;
  background-color: transparent;
  border: 0;
  padding: 0 16px 0 16px;
  font-size: 16px;
}

.catalog__filters .filters-row_inputs.unit-rent .form__row-wrap_with-uint.show:first-child input {
  padding-right: 70px;
}

.catalog__filters .filters-row_inputs.unit-rent .form__row-wrap_with-uint.show:last-of-type {
  width: 47.34%;
  margin-left: 0;
}

.catalog__filters .filters-row_inputs.unit-rent .form__row-wrap_with-uint.show:last-of-type input {
  padding-right: 60px;
}

.catalog__filters .filters-row_inputs.unit-sale .form__row-wrap_with-uint.show:first-child input {
  padding-right: 60px;
}

.catalog__filters .filters-row_inputs.unit-sale .form__row-wrap_with-uint.show:last-of-type input {
  padding-right: 50px;
}

.catalog__filters .filters-row_inputs .form__row-wrap_with-uint .value-text,
.catalog__filters .filters-row_inputs .form__row-wrap_with-uint .input_span_spoof {
  font-size: 16px;
  line-height: 22px;
}

.catalog__filters .filters-row_inputs .form__row-wrap_with-uint .value-text {
  color: #fff;
}

.catalog__filters .filters-row_inputs .form__row-wrap_with-uint:first-child {
  width: 52.26%;
}

.catalog__filters .filters-row_inputs .form__row-wrap_with-uint:first-child input {
  padding-right: 0;
}

.catalog__filters .filters-row_inputs .form__row-wrap_with-uint:first-child.show input {
  padding-left: 38px;
}

.catalog__filters .filters-row_inputs .form__row-wrap_with-uint:last-of-type {
  width: 47.34%;
}

.catalog__filters .filters-row_inputs .form__row-wrap_with-uint:last-of-type.show input {
  padding-left: 25px;
}

.catalog__filters .filters-row_inputs .form__row-wrap_with-uint:last-of-type .value-text {
  left: 0;
}

.catalog__filters .filters-row_inputs .form__row-wrap_with-uint:last-of-type input {
  padding-left: 0;
}

.catalog__filters .filters-row_inputs .form__row-wrap_with-uint:last-of-type .value-unit {
  left: 40px;
}

.catalog__filters .filters-row_inputs .msg {
  position: absolute;
  bottom: 0;
  left: 20px;
  display: none;
  font-size: 10px;
  color: #fff;
  letter-spacing: .1em;
  transform: translateY(calc(100% + 2px));
}

.catalog__filters .filters-radio {
  min-width: calc(50% - 8px);
}

.catalog__filters .filters-radio input {
  display: none;
}

.catalog__filters .filters-radio input:checked ~ .filters-radio__custom {
  color: var(--bg-color);
  background-color: #fff;
}

.catalog__filters .filters-radio input:checked ~ .filters-radio__custom:hover,
.catalog__filters .filters-radio input:checked ~ .filters-radio__custom:focus-visible {
  color: #fff;
  background-color: #8c8c8c;
}

.catalog__filters .filters-radio__custom {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 46px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 6px;
  cursor: pointer;
  transition: border-color .3s ease-in-out, color .3s ease-in-out, background-color .3s ease-in-out;
}

.catalog__filters .filters-radio__custom:hover,
.catalog__filters .filters-radio__custom:focus-visible {
  color: #8c8c8c;
  border-color: #8c8c8c;
}

.catalog__filters .select {
  width: 100%;
}

.catalog__filters .select .select-title {
  font-size: 16px;
}

.catalog__filters .filters__btn {
  font-size: 16px;
}

.catalog__list-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  max-width: calc(66.66% - 12px);
  width: 100%;
}

.catalog__list {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
  height: 100%;
}

.catalog__list .catalog-item-wrap {
  max-width: calc(50% - 12px);
}

.catalog__list_empty {
  display: none;
  width: 100%;
  height: 100%;
  font-weight: 600;
  font-size: 24px;
}

.catalog__more,
.catalog__back-top {
  width: 199px;
}

.catalog__back-top {
  display: none;
}

.detail {
  padding: 50px 0 100px;
  background-color: #191919;
}

.detail .container {
  display: flex;
  gap: 80px;
}

.detail__left {
  width: 48.94%;
}

.detail__back-link {
  display: block;
  margin-bottom: 56px;
}

.detail__back-link:hover .svg-ico {
  color: #8c8c8c;
}

.detail__back-link .svg-ico {
  color: #fff;
  transition: color .3s ease-in-out;
}

.detail__stickers {
  display: flex;
  gap: 24px;
  margin-bottom: 28px;
}

.detail__stickers .sticker {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.detail__stickers .sticker__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 6px;
  background-color: #8c8c8c;
}

.detail__stickers .sticker__icon .svg-ico {
  color: #fff;
}

.detail__stickers .sticker__text {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.1px;
}

.detail__place {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  width: max-content;
  margin-bottom: 40px;
  font-family: var(--montserrat);
  font-size: 20px;
  line-height: 1;
  cursor: pointer;
}

.detail__place .place-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  color: #fff;
  background-color: rgba(255,255,255,0.25);
  border-radius: 5px;
}

.detail__place .place-icon:hover ~ .detail__place-inf,
.detail__place .place-icon:focus-visible ~ .detail__place-inf {
  transform: translate(100%, -50%);
}

.detail__place-inf {
  position: absolute;
  right: -20px;
  top: 0;
  width: 280px;
  padding: 23px 24px 40px;
  background-color: #fff;
  border-radius: 6px;
  transform: scale(0);
}

.detail__place-inf.show {
  transform: translate(100%, -50%);
}

.detail__place-inf .inf__title {
  margin-bottom: 16px;
  color: var(--bg-color);
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.01em;
}

.detail__place-inf .inf__address,
.detail__place-inf .inf__height {
  margin-bottom: 24px;
  color: var(--bg-color);
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: -0.1px;
}

.detail__place-inf .inf__height {
  margin-bottom: 0;
}

.detail__price {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 30px;
}

.detail__price .price-into-month {
  font-family: var(--montserrat);
  font-size: 32px;
  font-weight: 600;
  line-height: 1;
}

.detail__price .price-into-year {
  font-family: var(--montserrat);
  font-size: 20px;
  line-height: 1;
}

.detail__quadrature {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 30px;
}

.detail__quadrature .quadrature__num {
  font-family: var(--montserrat);
  font-size: 32px;
  font-weight: 600;
  line-height: 1;
}

.detail__quadrature .quadrature__floor {
  font-family: var(--montserrat);
  font-size: 20px;
  line-height: 1;
}

.detail__description {
  max-width: 540px;
  margin-bottom: 30px;
  font-family: var(--montserrat);
  font-size: 16px;
  line-height: 1.13;
  text-shadow: 0 0 0 rgba(0,0,0,0.1),0 4px 9px rgba(0,0,0,0.1),-1px 16px 16px rgba(0,0,0,0.09),-3px 35px 21px rgba(0,0,0,0.05),-6px 62px 25px rgba(0,0,0,0.01),-9px 98px 27px rgba(0,0,0,0);
}

.detail__btn-wrapper {
  display: flex;
  gap: 24px;
}

.detail__btn {
  width: 199px;
}

.detail__download-pdf {
  width: 203px;
}

.detail__right {
  flex-shrink: 0;
  width: calc(100% - 48.94% - 27px);
}

.detail__slider {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.detail__slider.slick-slider {
  margin-bottom: 0 !important;
}

.detail__slider-navs {
  display: none;
}

.more-objects {
  padding: 33px 0 60px;
  background-color: #191919;
}

.more-objects__title {
  margin-bottom: 45px;
  font-family: var(--montserrat);
  font-size: 32px;
  font-weight: 600;
  line-height: 1;
}

.more-objects__list-wrap {
  position: relative;
  margin-bottom: 67px;
}

.more-objects__list {
  display: flex;
  gap: 24px;
}

.more-objects__list .catalog-item-wrap {
  display: none;
}

.more-objects__list .catalog-item-wrap:nth-child(1),
.more-objects__list .catalog-item-wrap:nth-child(2),
.more-objects__list .catalog-item-wrap:nth-child(3) {
  display: block;
}

.more-objects__list .catalog-item-wrap .catalog-item__images-wrap {
  width: 100%;
}

.more-objects__list .catalog-item-wrap .catalog-item__images {
  height: 100%;
}

.more-objects__list-nav {
  display: none;
}

.more-objects__link {
  width: 200px;
  margin: 0 auto;
}

.owners {
  padding: 211px 0 40px;
  background: transparent url("../img/owners.png") center/cover no-repeat;
}

.owners::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(16,16,17,0.5);
}

.owners .container {
  z-index: 1;
  display: flex;
  justify-content: space-between;
}

.owners__left {
  max-width: 41.36%;
  width: 100%;
}

.owners__title {
  font-family: var(--montserrat);
  font-size: 24px;
  font-weight: 600;
}

.owners__right {
  max-width: calc(100% - 41.36% - 117px);
  width: 100%;
}

.owners__form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.owners__form .msg {
  position: absolute;
  bottom: 0;
  left: 20px;
  display: none;
  font-size: 10px;
  color: #fff;
  letter-spacing: .1em;
  transform: translateY(calc(100% + 2px));
}

.owners__form .form-col {
  display: flex;
  gap: 24px;
}

.owners__form .smart-captcha {
  width: 271px;
  height: 85px;
}

.owners__form .form-row {
  position: relative;
  max-width: calc(50% - 12px);
  width: 100%;
}

.owners__form .common-input {
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  padding: 0 16px;
  color: var(--bg-color);
  font-size: 15px;
  letter-spacing: -0.1px;
  border: 0;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(16,24,40,0.04);
}

.owners__form .common-input::placeholder {
  color: #919BA7;
}

.owners__form .custom-checkbox-container {
  max-width: 100%;
}

.owners__form .custom-checkbox-container input {
  display: none;
}

.owners__form .custom-checkbox-container input:checked ~ .custom-checkbox-wrap .custom-checkbox::before {
  opacity: 1;
}

.owners__form .custom-checkbox-container .custom-checkbox-wrap {
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
}

.owners__form .custom-checkbox-container .custom-checkbox {
  position: relative;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 21px;
  border: 1px solid #fff;
  border-radius: 5px;
}

.owners__form .custom-checkbox-container .custom-checkbox::before {
  content: '✓';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 16px;
  height: 16px;
  margin: auto;
  color: #fff;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.1px;
  opacity: 0;
  transition: opacity .3s ease-in-out;
}

.owners__form .custom-checkbox-container .custom-checkbox__text {
  color: rgba(255,255,255,0.5);
  font-size: 12px;
  line-height: 17px;
  letter-spacing: -0.1px;
  user-select: none;
}

.owners__form .custom-checkbox-container .custom-checkbox__text a {
  color: #fff;
  text-decoration: underline;
}

.owners__form .btn_submit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  height: 46px;
  padding: 0 16px;
  color: #101011;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(16,24,40,0.04);
}

.owners__form .btn_submit:hover,
.owners__form .btn_submit:focus-visible {
  background-color: #8c8c8c;
  border-color: #8c8c8c;
  color: #fff;
}

.policy {
  padding: 33px 0 64px;
  background-color: #191919;
}

.policy__title {
  margin-bottom: 40px;
  font-family: var(--montserrat);
  color: #fff;
  font-size: 32px;
  font-weight: 600;
  line-height: 1;
}

.policy__text h6 {
  display: block;
  text-shadow: 0 0 0 rgba(0,0,0,0.1),0 4px 9px rgba(0,0,0,0.1),-1px 16px 16px rgba(0,0,0,0.09),-3px 35px 21px rgba(0,0,0,0.05),-6px 62px 25px rgba(0,0,0,0.01),-9px 98px 27px rgba(0,0,0,0);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
}

.policy__text p {
  margin-bottom: 15px;
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  line-height: 120%;
}

.policy__text p b {
  text-shadow: 0 0 0 rgba(0,0,0,0.1),0 4px 9px rgba(0,0,0,0.1),-1px 16px 16px rgba(0,0,0,0.09),-3px 35px 21px rgba(0,0,0,0.05),-6px 62px 25px rgba(0,0,0,0.01),-9px 98px 27px rgba(0,0,0,0);
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
}

.policy__text ul:not(:last-child) {
  margin-bottom: 15px;
}

.policy__text ul li {
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  line-height: 120%;
}

.footer {
  padding: 50px 0 40px;
  background-color: var(--bg-color);
}

.footer .container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.footer__link {
  width: max-content;
  font-size: 14px;
  line-height: 1.3;
  color: #fff;
  opacity: .8;
  transition: opacity .3s ease-in-out;
}

.footer__link:hover,
.footer__link:focus-visible {
  opacity: 1;
}

.footer__address {
  max-width: 430px;
  width: 100%;
}

.footer__social {
  display: flex;
  gap: 16px;
  margin-bottom: 12px;
}

.footer__social li a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  transition: background-color .3s ease-in-out;
}

.footer__social li:hover a,
.footer__social li:focus-visible a {
  background-color: #8c8c8c;
}

.footer__social li:hover .svg-ico,
.footer__social li:focus-visible .svg-ico {
  color: #fff;
}

.footer__social .svg-ico {
  color: var(--bg-color);
  transition: color .3s ease-in-out;
}

.footer__policy {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
}

.footer__policy:hover,
.footer__policy:focus-visible {
  text-decoration: underline;
}

.footer__copyright {
  color: rgba(255,255,255,0.25);
  font-size: 12px;
  line-height: 1.3;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none;
  background-color: rgba(0,0,0,0.6);
  backdrop-filter: blur(2.5px);
  cursor: pointer;
}

.modal__close {
  position: absolute;
  top: 14px;
  right: 19px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  color: var(--yellow);
  cursor: pointer;
}

.modal__close:hover .svg-ico {
  color: var(--yellow-hover);
}

.modal__close .svg-ico {
  color: var(--yellow);
  transition: color .3s ease-in-out;
}

.modal__content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  max-width: 477px;
  width: 100%;
  height: max-content;
  max-height: 100vh;
  margin: auto;
  padding: 24px 26.5px 27px;
  background-color: #191919;
  border-radius: 5px;
  cursor: default;
  overflow-y: scroll;
  scrollbar-width: none;
}

.modal__content::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.modal__header {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
}

.modal__close {
  position: absolute;
  top: 15px;
  right: 20px;
}

.modal__title {
  font-family: var(--montserrat);
  font-size: 32px;
  font-weight: 600;
  line-height: 1;
}

.modal__description p {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.1px;
}

.modal__form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.modal__form .modal__submit-btn {
  width: 200px;
  height: 46px;
}

.modal__form-row {
  position: relative;
  width: 100%;
}

.modal__form-row .modal-input {
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  padding: 0 16px;
  color: #fff;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.1px;
  border-radius: 6px;
  border: 1px solid #FFF;
  background: #101011;
  box-shadow: 0 1px 2px 0 rgba(16,24,40,0.04);
  transition: border-color .3s ease-in-out;
}

.modal__form-row .modal-input:hover,
.modal__form-row .modal-input:focus-visible {
  border-color: #8c8c8c;
}

.modal__form-row .msg {
  position: absolute;
  bottom: 0;
  left: 20px;
  display: none;
  font-size: 10px;
  color: #fff;
  letter-spacing: .1em;
  transform: translateY(calc(100% + 2px));
}

.modal__form-row.custom-checkbox-container input {
  display: none;
}

.modal__form-row.custom-checkbox-container input:checked ~ .custom-checkbox-wrap .custom-checkbox {
  background-color: #fff;
}

.modal__form-row.custom-checkbox-container input:checked ~ .custom-checkbox-wrap .custom-checkbox::before {
  opacity: 1;
}

.modal__form-row.custom-checkbox-container .custom-checkbox-wrap {
  display: flex;
  gap: 23px;
  cursor: pointer;
}

.modal__form-row.custom-checkbox-container .custom-checkbox {
  position: relative;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  width: 22px;
  height: 21px;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 5px;
  transition: background-color .3s ease-in-out;
}

.modal__form-row.custom-checkbox-container .custom-checkbox::before {
  content: '✓';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 16px;
  height: 16px;
  margin: auto;
  color: #000;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.1px;
  opacity: 0;
  transition: opacity .3s ease-in-out;
}

.modal__form-row.custom-checkbox-container .custom-checkbox__text {
  color: #8c8c8c;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: -0.1px;
}

.modal__form-row.custom-checkbox-container .custom-checkbox__text a {
  color: #fff;
  text-decoration-line: underline;
}

@media screen and (max-width: 1558.98px) {
  .map-section {
    overflow: auto;
    scrollbar-width: none;
  }

  .map-section::-webkit-scrollbar {
    width: 0;
  }
}

@media screen and (max-width: 1359.98px) {
  :root {
    --font-size: 17px;
    --container: 100vw;
  }

  .container {
    padding: 0 20px;
  }

  .header .container {
    padding: 16px 20px;
  }

  .catalog-item-wrap .catalog-item__images-wrap {
    width: calc((var(--container) - 60px) / 3 - 16px);
  }

  .main-hero .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .about .container {
    padding-left: 20px;
    padding-right: calc((100vw - var(--container)) / 2 - 80px);
  }
}

@media screen and (max-width: 1249.98px) {
  .main-hero__form .select .select-title {
    padding: 12px 7px;
    font-size: 12px;
  }

  .main-hero__form .common-input {
    padding: 12px 7px;
    font-size: 12px;
  }
}

@media screen and (max-width: 1029.98px) {
  .desktop {
    display: none !important;
  }

  .tablet {
    display: block;
  }

  .catalog-item-wrap {
    max-width: calc(50% - 12px);
    height: calc((100vw - 40px) / 2.87);
  }

  .catalog-item-wrap .catalog-item__image {
    height: calc((100vw - 40px) / 2.87);
  }

  .main-hero .container {
    gap: 139px;
    padding-top: 190px;
    padding-bottom: 42px;
  }

  .main-hero__title {
    font-family: var(--montserrat);
    font-size: 45px;
    line-height: 60px;
    letter-spacing: -0.1px;
  }

  .main-hero__form {
    padding: 23px 42.5px 32px;
  }

  .main-hero__form .form__row {
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .main-hero__form .form__row>*:not(.form-submit) {
    width: calc(50% - 12px);
  }

  .main-hero__form .select .select-title {
    padding: 12px 16px;
    font-size: 15px;
  }

  .main-hero__form .select,
  .main-hero__form .common-input {
    width: calc(50% - 12px);
  }

  .main-hero__form .form__row-wrap_with-uint {
    width: calc(50% - 12px);
  }

  .main-hero__form .common-input {
    padding: 12px 16px;
    font-size: 15px;
  }

  .main-hero__form .form-submit {
    width: 99px;
    height: 46px;
  }

  .about {
    padding-top: 58.5px;
  }

  .about .container {
    flex-direction: column;
    gap: 44px;
    padding-right: 20px;
  }

  .about__left {
    gap: 24px;
    max-width: 100%;
    padding: 0;
  }

  .about__right {
    width: 100%;
  }

  .main-catalog__tabs-wrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 22px;
    margin-bottom: 60px;
  }

  .main-catalog__tabs li {
    font-size: 15px;
  }

  .main-catalog__wrap {
    margin-bottom: 63px;
  }

  .main-catalog__list .catalog-item-wrap {
    max-width: calc(50% - 12px);
    height: calc(((100vw - 40px) / 2 - 24px) / .83);
  }

  .main-catalog__list .catalog-item-wrap .catalog-item__images-wrap {
    width: 100%;
  }

  .main-catalog__list .catalog-item-wrap .catalog-item__image {
    height: 100%;
  }

  .main-catalog__list .catalog-item-wrap .catalog-item__image img {
    height: 100%;
    object-fit: cover;
  }

  .map-section {
    padding-top: 82.5px;
  }

  .map-section__title {
    margin-bottom: 66.5px;
    padding: 0 20px;
    max-width: 100%;
  }

  .catalog {
    padding-bottom: 80px;
  }

  .catalog__filters {
    max-width: calc(50% - 12px);
  }

  .catalog__list-wrap {
    gap: 83px;
    max-width: calc(50% - 12px);
  }

  .catalog__list .catalog-item-wrap {
    max-width: calc((100vw - 40px) / 2 - 12px);
    height: calc(((100vw - 40px) / 2 - 24px) / .83);
  }

  .catalog__list .catalog-item-wrap .catalog-item__images-wrap {
    width: 100%;
  }

  .catalog__list .catalog-item-wrap .catalog-item__images {
    height: 100%;
  }

  .catalog__list .catalog-item-wrap .catalog-item__image {
    width: calc((100vw - 40px) / 2 - 12px) !important;
    height: calc(((100vw - 40px) / 2 - 24px) / .83);
  }

  .catalog__more,
  .catalog__back-top {
    width: 100%;
  }

  .detail {
    padding: 50px 0 150px;
  }

  .detail .container {
    flex-direction: column;
    gap: 50px;
  }

  .detail__left {
    width: 100%;
  }

  .detail__place {
    margin-bottom: 35px;
  }

  .detail__btn-wrapper {
    position: absolute;
    left: 20px;
    bottom: -40px;
    width: calc(100vw - 40px);
    transform: translateY(100%);
  }

  .detail__right {
    width: 100%;
  }

  .detail__slider {
    flex-direction: row;
  }

  .detail__slider .slider-item {
    width: calc(100vw - 40px) !important;
  }

  .detail__slider-navs {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    display: flex;
    margin: auto;
  }

  .more-objects {
    padding-bottom: 30px;
  }

  .more-objects__title {
    margin-bottom: 76px;
  }

  .more-objects__list .catalog-item-wrap {
    max-width: calc(50% - 12px);
    height: calc(((100vw - 40px) / 2 - 24px) / .83);
  }

  .more-objects__list .catalog-item-wrap:nth-child(3) {
    display: none;
  }

  .more-objects__list .catalog-item-wrap .catalog-item__image {
    width: calc(((100vw - 40px) / 2 - 12px)) !important;
    height: calc(((100vw - 40px) / 2 - 24px) / .83);
  }

  .owners {
    padding: 178px 0 60px;
  }

  .owners .container {
    flex-direction: column;
    gap: 55px;
  }

  .owners__left {
    max-width: 100%;
  }

  .owners__right {
    max-width: 100%;
  }

  .policy {
    padding: 50px 0;
  }

  .policy__title {
    margin-bottom: 24px;
  }

  .footer__policy {
    margin-bottom: 21px;
    font-family: var(--montserrat);
    font-weight: 600;
  }
}

@media screen and (max-width: 807.98px) {
  :root {
    --font-size: 14px;
  }

  .desktop-tablet {
    display: none !important;
  }

  .mobile {
    display: block;
  }

  .header .container>.header__phone {
    margin-right: 11px;
    margin-left: auto;
  }

  .header .container>.header__phone {
    display: block;
  }

  .header__logo .logo-mobile {
    display: block;
  }

  .header__logo .logo-desktop {
    display: none;
  }

  .header__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    background-color: var(--bg-color);
    transform: scale(0);
  }

  .header__wrapper.open {
    opacity: 1;
    transform: scale(1);
  }

  .header__wrapper .hamburger-wrap {
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .hamburger-wrap__close {
    position: absolute;
    top: 20px;
    right: 20px;
    display: block;
    cursor: pointer;
  }

  .header__nav ul {
    flex-direction: column;
  }

  .header__socials li a {
    background-color: rgba(255,255,255,0.25);
  }

  .header__hamburger {
    width: 24px;
    height: 24px;
    display: block;
    cursor: pointer;
    user-select: none;
  }

  .select-content {
    max-height: 227.5px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: var(--main-color) var(--bg-color);
  }

  .catalog-item-wrap .catalog-item__images-wrap {
    width: 100%;
  }

  .about__right img {
    height: max-content;
    object-fit: contain;
  }

  .main-catalog__wrap {
    position: relative;
  }

  .main-catalog__navs {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(-20px);
  }

  .map-section .container {
    padding: 0;
    max-width: 100vw;
  }

  #map {
    height: 761px;
  }

  #map .container {
    position: relative;
  }

  #map .bars-content__item-wrapper {
    position: absolute;
    top: 0 !important;
    right: 0;
    bottom: 0;
    left: 0 !important;
    margin: auto;
    transform: translate(-50%, -30%);
  }

  #map .bars-content__item>*>* {
    flex-direction: column;
  }

  .catalog__filters .filters-row_inputs.unit-rent .form__row-wrap_with-uint:last-of-type {
    width: calc(47.34% + 16px);
    margin-left: -16px;
  }

  .catalog__filters .filters-row_inputs.unit-rent .form__row-wrap_with-uint:last-of-type input {
    padding-right: 0;
  }

  .detail__slider .slider-item img {
    height: auto;
  }

  .owners {
    padding: 161px 0 42px;
  }

  .policy {
    padding: 33px 0 50px;
  }

  .policy__title {
    font-size: 24px;
  }

  .modal__form .modal__submit-btn {
    width: 100%;
  }
}

@media screen and (max-width: 644.98px) {
  .btn {
    font-size: 15px;
  }

  .catalog-item-wrap {
    max-width: 100%;
    height: calc((100vw - 40px) / .91);
  }

  .catalog-item-wrap .catalog-item__image {
    height: calc((100vw - 40px) / .91);
  }

  .main-hero .container {
    gap: 36px;
    padding-top: 127px;
    padding-bottom: 60px;
  }

  .main-hero__title {
    font-size: 24px;
    line-height: 38px;
  }

  .main-hero__form {
    padding: 23px 23.5px 26px;
  }

  .main-hero__form .form__row>*:not(.form-submit) {
    width: 100%;
  }

  .main-hero__form .select,
  .main-hero__form .common-input {
    width: 100%;
  }

  .main-hero__form .form__row-wrap_with-uint {
    width: 100%;
  }

  .about {
    padding-top: 60px;
  }

  .about .container {
    gap: 36px;
  }

  .about__description p {
    font-size: 14px;
  }

  .about__right {
    width: 100vw;
    margin-left: -20px;
  }

  .main-catalog__tabs-wrapper {
    margin-bottom: 45px;
  }

  .main-catalog__tabs-wrapper.tabs-big {
    display: none;
  }

  .main-catalog__tabs-wrapper.tabs-small {
    display: flex;
  }

  .main-catalog__wrap {
    margin-bottom: 69px;
  }

  .main-catalog__list {
    margin-bottom: 20px !important;
  }

  .main-catalog__list .catalog-item-wrap {
    max-width: 100%;
    height: calc((100vw - 40px) / .83);
  }

  .main-catalog__navs {
    display: flex;
  }

  .map-section__title {
    margin-bottom: 62.5px;
    font-size: 16px;
  }

  .catalog {
    padding: 35px 0;
  }

  .catalog .container {
    flex-direction: column;
    gap: 50px;
  }

  .catalog__filters {
    max-width: 100%;
  }

  .catalog__list-wrap {
    gap: 43px;
    max-width: 100%;
  }

  .catalog__list .catalog-item-wrap {
    max-width: calc(100vw - 40px);
    height: calc((100vw - 40px) / .91);
  }

  .catalog__list .catalog-item-wrap .catalog-item__image {
    width: calc(100vw - 40px) !important;
    height: calc((100vw - 40px) / .91);
  }

  .detail {
    padding: 39px 0 140px;
  }

  .detail .container {
    gap: 30px;
  }

  .detail__back-link {
    margin-bottom: 31px;
  }

  .detail__back-link .svg-ico {
    width: 34px;
    height: 27px;
  }

  .detail__place .place-icon:hover ~ .detail__place-inf,
  .detail__place .place-icon:focus-visible ~ .detail__place-inf {
    transform: translate(48.5%, 0%);
  }

  .detail__place-inf.show {
    transform: translate(48.5%, 0%);
  }

  .detail__description {
    margin-bottom: 0;
  }

  .detail__btn {
    width: calc(50% - 10px);
  }

  .detail__download-pdf {
    width: calc(50% - 10px);
  }

  .more-objects {
    padding-bottom: 60px;
  }

  .more-objects__list-wrap {
    margin-bottom: 74px;
  }

  .more-objects__list {
    margin-bottom: 20px !important;
  }

  .more-objects__list .catalog-item-wrap {
    display: block !important;
    max-width: 100%;
    height: calc((100vw - 40px) / .91);
  }

  .more-objects__list .catalog-item-wrap .catalog-item__image {
    width: calc(100vw - 40px) !important;
    height: calc((100vw - 40px) / .91);
  }

  .more-objects__list-nav {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    display: flex;
    margin: auto;
  }

  .owners .container {
    gap: 24px;
  }

  .owners__form .form-col {
    flex-direction: column;
  }

  .owners__form .form-row {
    max-width: 100%;
  }

  .footer__policy {
    margin-bottom: 42px;
  }

  .modal__content {
    max-width: 90vw;
  }
}

@media screen and (max-width: 375.98px) {
  .modal__close {
    top: 13px;
    right: 13px;
  }
}